<mat-toolbar color="primary">
  <mat-toolbar-row>
    <a routerLink="/" fxLayout fxLayoutAlign="center center">
      <img src="../../../../../assets/images/logo-nft.svg" alt="NFT Logo">
    </a>
    <div>
      <ng-content></ng-content>
    </div>
  </mat-toolbar-row>
</mat-toolbar>
