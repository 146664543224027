<mat-toolbar>
  <a routerLink="/" fxLayout fxLayoutAlign="center center">
    <img src="../../../../../assets/images/logo-nft.svg" alt="NFT Logo">
  </a>
  <a mat-button routerLink="account" class="courrier-font"> my NFTs </a>
  <a mat-button (click)="connect()" class="courrier-font">{{ !!(metamaskService.account$ | async) ? 'connected': 'connect' }}</a>  
</mat-toolbar>

<main>
  <router-outlet></router-outlet>
</main>
<nft-footer></nft-footer>
